(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/oddset-kambi/components/maintenance-context/assets/javascripts/maintenance-context-provider.js') >= 0) return;  svs.modules.push('/modules/oddset-kambi/components/maintenance-context/assets/javascripts/maintenance-context-provider.js');
"use strict";

var _svs$oddset_kambi$dat, _svs$oddset_kambi$dat2;

const {
  useState
} = React;
const {
  MaintenanceContext
} = svs.oddset_kambi.components.maintenance_context;
const {
  isSvSMaintenance
} = (_svs$oddset_kambi$dat = (_svs$oddset_kambi$dat2 = svs.oddset_kambi.data) === null || _svs$oddset_kambi$dat2 === void 0 ? void 0 : _svs$oddset_kambi$dat2.maintenanceConfig) !== null && _svs$oddset_kambi$dat !== void 0 ? _svs$oddset_kambi$dat : false;
const {
  MaintenanceView
} = svs.oddset_kambi.components.maintenance_view;
const kambiMaintenanceText = 'Underhåll pågår hos vår leverantör för att förbättra tjänsten.';
const MaintenanceContextProvider = _ref => {
  let {
    children
  } = _ref;
  const [kambiMaintenance, setKambiMaintenance] = useState(null);
  const isUnderMaintenance = Boolean(kambiMaintenance === null || kambiMaintenance === void 0 ? void 0 : kambiMaintenance.isMaintenance) || isSvSMaintenance;
  return React.createElement(MaintenanceContext.Provider, {
    value: {
      setKambiMaintenance
    }
  }, isUnderMaintenance ? React.createElement(MaintenanceView, {
    maintenanceText: (kambiMaintenance === null || kambiMaintenance === void 0 ? void 0 : kambiMaintenance.isMaintenance) && "".concat(kambiMaintenanceText, " Vi ber\xE4knar att vara klara senast ").concat(dateFns.format(kambiMaintenance.endingAt, "yyyy-MM-dd 'kl.' HH:mm"))
  }) : children);
};
setGlobal('svs.oddset_kambi.components.maintenance_context.MaintenanceContextProvider', MaintenanceContextProvider);

 })(window);